import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mr-0 justify-content-center ml-2 mr-2" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomerTable = _resolveComponent("CustomerTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_CustomerTable, {
            onCustomerTableRowClick: _ctx.handleClick,
            customers: _ctx.getRecentlyViewedCustomers,
            allowRemove: true,
            onRemoveCustomer: _ctx.handleRemoveCustomer
          }, null, 8, ["onCustomerTableRowClick", "customers", "onRemoveCustomer"])
        ]),
        _: 1
      })
    ])
  ]))
}